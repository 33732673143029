<template>
    <v-col cols="1" class="d-flex  align-end " style="margin-bottom: -4%;">
        <v-btn color="grey" v-if="operator" outlined small fab @click="showChangeOperator" v-click-outside="hide">
            {{ operator }}
        </v-btn>
        <div v-if="isChangeOperator" class="change-operator">
            <button class="change-operator__item" @click="changeOperator(newOperator)">{{ newOperator.toUpperCase() }}</button>
        </div>
    </v-col>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
    name: "OperatorButton",
    directives: {
        ClickOutside
    },
    data(){
        return{
            isChangeOperator: false
        }
    },
    props: {
        operator: {
            type: String
        },
    },
    methods: {
        showChangeOperator(){
            this.isChangeOperator = !this.isChangeOperator
            console.log('showChangeOperator')
        },
        changeOperator(newOperator){
            this.$emit('changeOperator', newOperator)
            this.isChangeOperator = !this.isChangeOperator
        },
        hide(){
            this.isChangeOperator = false
        }
    },
    computed: {
        newOperator(){
            return this.operator === 'and'
                ? 'or'
                : 'and'
        }
    }
}
</script>

<style lang="scss">
.change-operator{
    position: relative;
    z-index: 10;

    &__item{
      font-size: 12px;
      color: gray;
      border: 1px solid gray;
      border-radius: 5px;
      padding: 2px;
      width: 40px;
      justify-content: center;
      display: flex;
      margin: 0 0 7px 10px;
    }
}

</style>