<template>
    <v-card class="rounded-lg popup-width">
        <v-card-title> {{title}}</v-card-title>
        
        <div class="px-5">
            <slot></slot>
        </div>
        <buttonsPopup 
            @passiveBtn="$emit('passiveBtn')" 
            @activeBtn="$emit('activeBtn')" 
            :activeBtn="activeBtn"
            :passiveBtn="passiveBtn"
            :isActiveBtn="isActiveBtn"
        />
    </v-card>
    
</template>

<script>
import buttonsPopup from './buttonsForPopup.vue';
export default {
    name: 'cardPopup',
    components: {
        buttonsPopup,
    },
    props: {
        title:{
            type: String
        },
        activeBtn: {
            type: String
        },
        passiveBtn: {
            type: String
        },
        isActiveBtn: {
            type: Boolean,
            default: true
        }
    },
}
</script>

<style scoped>
    .popup-width {
        width: 70% !important;
    }
</style>