<template>
  <v-card-actions class="px-4">
    <v-spacer></v-spacer>
    <v-btn
        small
        color="grey"
        class="white--text mr-1"
        @click="$emit('passiveBtn')"
        elevation="0"
    >{{ $t(`${passiveBtn}`) }}</v-btn>
    <v-btn
        small
        color="deep-purple accent-2"
        class="white--text"
        @click="$emit('activeBtn')"
        elevation="0"
        :disabled="!isActiveBtn"
    >{{ $t(`${activeBtn}`) }}</v-btn>
    </v-card-actions>
</template>

<script>
export default {
    props: {
        activeBtn: {
            type: String,
            default: 'save'
        },
        passiveBtn: {
            type: String,
            default: 'cancel'
        },
        isActiveBtn: {
            type: Boolean
        }
    }
}
</script>

<style>

</style>