export default {
    data(){
      return{
        locationPopup: 0,
        topOffset: 100,
      }
    },
    computed: {
      positionPopup(){
        const offset = document.querySelectorAll('.v-dialog.v-dialog--active.v-dialog--persistent.v-dialog--fullscreen')[0].scrollTop
        return offset - this.topOffset
      },
    },
    methods: {
      setLocationPopup(location){
        this.locationPopup = location
      }
    }
  }
  