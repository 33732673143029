export default {
  methods: {
    copyId(e){
      const id = e.target.innerText.trim()

      navigator.clipboard.writeText(id)
        .then(() => {
          console.log('Текст успешно скопирован в буфер обмена');
        })
        .catch(err => {
          console.error('Произошла ошибка при копировании текста: ', err);
        })
    }
  }
}